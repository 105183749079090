// eslint-disable-next-line import/no-extraneous-dependencies
import { Category } from '@gemini-vsf/api-client';
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { categoryGetters } from '@gemini-vsf/composables';

const nonFilters = new Set([
  'page',
  'sort',
  'term',
  'itemsPerPage',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'gclid',
  '___store',
  'ranMID',
  'raneaid',
  'ranSiteID',
  'siteID',
  'wt_st',
  'wt_pa',
  'cjevent',
  '_kx',
  'fbclid',
  '_gl'
]);

const reduceFilters = (query) => (prev, curr) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
  const makeArray = Array.isArray(query[curr]) || nonFilters.has(curr);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...prev,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    [curr]: makeArray ? query[curr] : [query[curr]],
  };
};

const useUiHelpers = () => {
  const route = useRoute();
  const router = useRouter();
  const { query } = route.value;

  const getFiltersDataFromUrl = (onlyFilters) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Object.keys(query)
      .filter((f) => (onlyFilters ? !nonFilters.has(f) : nonFilters.has(f)))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce(reduceFilters(query), {});

  const getFacetsFromURL = () => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filters: getFiltersDataFromUrl(true),
    itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 24,
    page: Number.parseInt(query.page as string, 10) || 1,
    sort: (query.sort as string) || '',
    term: query.term as string,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  const getCatLink = (category: Category): string => categoryGetters.getSlug(category);

  const changeSorting = async (sort: string) => {
    await router.push({ query: { ...query, sort } });
  };

  const changeFilters = async (filters: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const filtersDataFromUrl = getFiltersDataFromUrl(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (Object.keys(filtersDataFromUrl).includes('page')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      delete filtersDataFromUrl.page;
    }
    await router.push({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      query: {
        ...filtersDataFromUrl,
        ...filters,
      },
    });
  };

  const setTermForUrl = async (term: string) => {
    await router.push({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined,
      },
    });
  };

  return {
    getFacetsFromURL,
    getCatLink,
    changeSorting,
    changeFilters,
    setTermForUrl,
  };
};

export default useUiHelpers;
